import React, { useEffect } from 'react';

function UseScript() {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = "//search.freezag.com/iframe.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <>Hi there, AI is generating travel plans for you. Hold on for a moment..</>
    // JSX goes here
  );
}
export default UseScript;