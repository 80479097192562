import React from "react";
import '../../App.css';
import UseScript from "../hooks/useScript";



 function Services() {
 
    
return (
   
        <UseScript/>
  
)

}
export default Services;